import React from 'react';

export const OwnershipLegend = () => (
  <div
    id='legend-main_ownership_9213'
    className='esriLegendService'
    style={{ display: 'block' }}
  >
    <table width='95%'>
      <tbody>
        <tr>
          <td align='left'>
            <span className='esriLegendServiceLabel'>
              ownership - Land Ownership
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div id='legend-main_ownership_9213_0' style={{ display: 'block' }}>
      <table width='95%' className='esriLegendLayerLabel'>
        <tbody>
          <tr>
            <td align='left' />
          </tr>
        </tbody>
      </table>
      <table
        cellPadding={0}
        cellSpacing={0}
        width='95%'
        className='esriLegendLayer'
      >
        <tbody>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(199, 169, 117)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Local Government</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(78, 137, 72)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Non-Profit Conservancies and Trusts</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(232, 101, 77)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>
                      CA Dept. of Forestry and Fire Protection
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(165, 254, 253)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>CA Dept. of Parks and Recreation</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(124, 205, 239)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>CA Dept. of Fish and Wildlife</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(57, 127, 192)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Other State Lands</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(231, 183, 252)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>National Park Service</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(147, 207, 131)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>USDA Forest Service</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(220, 162, 52)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Bureau of Reclamation</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(246, 246, 45)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Bureau of Land Management</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(190, 199, 225)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>US Fish and Wildlife Service</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(250, 236, 189)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Tribal Trust Lands</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(245, 198, 204)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Department of Defense</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td width={35} align='center'>
              <div style={{ width: '30px', height: '30px' }}>
                <svg
                  overflow='hidden'
                  width={30}
                  height={30}
                  style={{ touchAction: 'none' }}
                >
                  <defs />
                  <path
                    fill='rgb(169, 42, 52)'
                    fillOpacity={1}
                    stroke='none'
                    strokeOpacity={0}
                    strokeWidth={1}
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit={4}
                    path='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
                    d='M-10-10L 10 0L 10 10L-10 10L-10-10Z'
                    fillRule='evenodd'
                    transform='matrix(1.00000000,0.00000000,0.00000000,1.00000000,15.00000000,15.00000000)'
                  />
                </svg>
              </div>
            </td>
            <td>
              <table width='95%'>
                <tbody>
                  <tr>
                    <td align='left'>Other Federal Lands</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
